var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"t9ueRsKNE88-0p7S1nIgV"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/pp_parcel_monitor_community/99e336a9";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const SENTRY_DSN = publicRuntimeConfig.sentryDsn;
const SENTRY_ENV = publicRuntimeConfig.sentryEnvironment;

const isRunTimeEnvVariableReady = SENTRY_DSN && SENTRY_ENV;
const isBuildTimeEnvVariableReady = process.env.NEXT_PUBLIC_SENTRY_DSN && process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
const isProduction = (SENTRY_ENV ?? process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT) === 'production';
const PRODUCTION_SENTRY_SAMPLE_RATE = 0.5;

if (isRunTimeEnvVariableReady || isBuildTimeEnvVariableReady) {
  Sentry.init({
    dsn: SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: SENTRY_ENV ?? process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    sampleRate: isProduction ? PRODUCTION_SENTRY_SAMPLE_RATE : 0.25,
    attachStacktrace: true,
  });
}
